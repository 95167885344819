@font-face {
font-family: brasilia-delight-regular;
src: url(brasilia-delight-regular.otf);
}

body {
margin: 0;
padding: 0;
height: 100vh;
font-family: TimesNewRoman;
font-size: 18px;
background-color: #FFFFFF;
color: #4f4f4f;
}
.error {
border-color: #fc6464;
  background-color: #ff9d9d;
}
a {
text-decoration: none;
}
a, a:active, a:visited {
color: #000000;
}
#wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
background-image: url('../resources/wildflowers.png');
background-repeat: repeat-x;
background-position: center bottom;
}
#dynamicWrap {
  min-width: 300px;
  max-width: 400px;
  width: 80%;
  max-height: 80vh;
  min-height: 200px;
	background-color: rgba(255,255,255,0.6);
	border-radius: 10px;
}

.clearInputs {
text-align: center;
}

h1 {
margin: 0;
padding: 0;
color: #4f4f4f;
font-size: 60px;
font-family: brasilia-delight-regular;
text-align: center;
}
h3 {
margin: 0;
padding: 0;
color: #4f4f4f;
font-family: brasilia-delight-regular;
text-align: center;
}
.react-confirm-alert-body h1 {
color: #4f4f4f;
font-size: 30px;
font-family: TimesNewRoman;
text-align: center;
margin-bottom: 20px;
}
.theNames h3 {
color: #4f4f4f;
font-size: 20px;
font-family: TimesNewRoman;
text-align: center;
}
.react-confirm-alert-body {
	text-align: center;
}
.dateTime{
	text-align: center;
}
.infoBox {
text-align: center;
margin: 20px;
}
.inlineIt {
	display: inline-block;
	vertical-align: middle;
}
.widthIt {
	width: 150px;
}

textarea {
width: calc(100% - 25px);
resize: none;
height: 100px;
margin: 10px;
}
textarea:focus {
outline: none;
}
button {
	width: 100%;
height: 40px;
font-size: 16px;
padding: 0px;
margin: 0;
border: 2px solid #aaaaaa;
border-radius: 5px;
background-color: #ffffff;
cursor: pointer;
}
.loaderWrap {
display: none;
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
}
.loader {
border: 6px solid #f3f3f3;
border-top: 6px solid #ccc;
border-radius: 50%;
width: 50px;
height: 50px;
animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  z-index: 99;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
h3 {
text-align: center;
}