.home {
	max-height: 80vh;
  min-height: 200px;
  position: relative;
}
.partySearch {
position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
.nameLabel {
position: absolute;
  top: -20px;
  font-size: 12px;
  left: 50%;
  transform: translateX(-50%);
  padding: 1px;
  width: 100%;
}
.name {
line-height: 40px;
  width: calc(100% - 10px);
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #ababab;
  margin-bottom: 10px;
}
.emailCode {
display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #ababab;
  width: 20px;
  text-align: center;
  margin: 5px;
  font-size: 18px;
}

.suggestions {
background-color: #fafafa;
  border-top: 1px solid #ababab;
  left: 50%;
  list-style: none;
  margin: 0px;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 95%;
  z-index: 1;
  margin-top: -11px;
}

.suggestions li {
	padding: 5px 10px;
	cursor: pointer;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}